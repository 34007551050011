<template>
  <div class="container-center-horizontal">
    <div class="buttons screen">
      <div class="overlap-group5">
        <div class="vertical-stories">
          <div class="stories-1">
            <div class="flex-row">
              <img class="rectangle-20" src="@/assets/rectangle-26@1x.png" />
              <div class="text-1 raleway-black-selago-40px">
                <i18n path="global.title" />
              </div>
            </div>
            <div class="overlap-group3">
              <div class="graphic-elements custom-radius"></div>
              <div class="your-image-here">
                <div class="overlap-group2">
                  <!-- <div class="slideshow-container custom-radius">
                    <div class="mySlides fade">
                      <img src="@/assets/Roka-ar-telefonu.png" class="slide-img" />
                    </div>
                    <div class="mySlides fade">
                      <img src="@/assets/meitene-file-search.png" class="slide-img" />
                    </div>
                    <div class="mySlides fade">
                      <img src="@/assets/meitene-darbiba.png" class="slide-img" />
                    </div>
                    <div class="mySlides fade">
                      <img src="@/assets/meitene-darbiba2.png" class="slide-img" />
                    </div>
                  </div> -->
                </div>
                <!-- <router-link to="/prescan">
                  <div class="overlap-group custom-button">
                    <div class="text-2 smart-layers-pointers"></div>
                  </div>
                </router-link>
                <router-link to="/file">
                  <div class="overlap-group1 custom-button">
                    <div class="text-3 smart-layers-pointers"></div>
                  </div>
                </router-link> -->
              </div>
              <div class="text">
                <h1 class="title">
                  <i18n path="preview.vertical" />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <!-- <Footer :backType="2" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.buttons {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.buttons .overlap-group5 {
  height: 1920px;
  position: relative;
  width: 1080px;
}

.buttons .vertical-stories1 {
  display: flex;
  height: 1920px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.buttons .stories-1 {
  align-items: flex-start;
  background-color: var(--torea-bay);
  display: flex;
  flex-direction: column;
  min-height: 1920px;
  padding: 66px 18px;
  width: 1080px;
}

.buttons .flex-row {
  align-items: flex-start;
  display: flex;
  height: 158px;
  min-width: 876px;
}

.buttons .rectangle-20 {
  height: 144px;
  object-fit: cover;
  width: 144px;
}

.buttons .text-1 {
  align-self: flex-end;
  letter-spacing: 12px;
  line-height: 64px;
  margin-left: 14px;
  min-height: 64px;
  min-width: 718px;
  text-align: center;
  white-space: nowrap;
}

.buttons .overlap-group3 {
  align-self: center;
  height: 1428px;
  margin-right: 59.24px;
  margin-top: 79px;
  position: relative;
  width: 851px;
}

.buttons .graphic-elements {
  background-color: var(--link-water);
  height: 1416px;
  left: 262px;
  position: absolute;
  top: 0;
  width: 589px;
}

.buttons .your-image-here {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 287px;
  min-height: 1281px;
  position: absolute;
  top: 58px;
  width: 536px;
}

.buttons .overlap-group2 {
  height: 415px;
  margin-left: -0.5px;
  position: relative;
  width: 537px;
}

.buttons .your-image-here-1 {
  /* border: 1px solid var(--perano);*/
  height: 399px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 537px;
}

.buttons .rectangle {
  height: 398px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 536px;
}

.buttons .group-36 {
  background-image: url(~@/assets/group-32@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 414px;
  left: 76px;
  position: absolute;
  top: 0;
  width: 384px;
}

.buttons .overlap-group {
  align-items: flex-end;
  background-image: url(~@/assets/group-30@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 398px;
  justify-content: flex-end;
  margin-top: 35px;
  min-width: 536px;
  padding: 57.1px 132px;
}

.buttons .text-2 {
  -webkit-text-stroke: 2px var(--white);
  color: var(--torea-bay);
  cursor: pointer;
  font-family: var(--font-family-fontawesome);
  font-size: 250px;
  letter-spacing: 0;
  line-height: 218.1px;
  min-height: 252px;
  min-width: 270px;
  pointer-events: auto;
  text-align: center;
  text-shadow: -10px 10px 6px #00000029;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.buttons .text-2:hover {
  transform: scale(1.1);
}

.buttons .overlap-group1 {
  align-items: flex-end;
  background-image: url(~@/assets/group-30@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 398px;
  justify-content: flex-end;
  margin-top: 35px;
  min-width: 536px;
  padding: 23.4px 105px;
}

.buttons .text-3 {
  -webkit-text-stroke: 2px #3f3913;
  color: #ffe54d;
  font-family: var(--font-family-fontawesome);
  font-size: 300px;
  letter-spacing: 0;
  line-height: 218.1px;
  min-height: 302px;
  min-width: 324px;
  pointer-events: auto;
  text-align: center;
  text-shadow: -10px 10px 6px #00000029;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.buttons .text-3:hover {
  transform: scale(1.1);
}

.buttons .text {
  display: flex;
  height: 891px;
  left: 0;
  position: absolute;
  top: 537px;
  width: 260px;
}

.buttons .title {
  color: var(--wild-sand);
  flex: 1;
  font-family: var(--font-family-cormorant_garamond);
  font-size: var(--font-size-xxxxxl);
  letter-spacing: 1.48px;
  line-height: 150px;
  margin-bottom: 339.5px;
  margin-left: -356px;
  margin-right: 0px;
  margin-top: 370.5px;
  transform: rotate(-90deg);
  white-space: nowrap;
  width: 893px;
}

.buttons .component-1-2 {
  align-items: flex-end;
  display: flex;
  height: 118px;
  left: 0;
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.buttons .overlap-group4 {
  height: 120px;
  margin-bottom: -2px;
  position: relative;
  width: 1080px;
}

.buttons .rectangle-30 {
  background-color: var(--torea-bay);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.buttons .text-4 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.buttons .text-5 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.buttons .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.buttons .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}
</style>